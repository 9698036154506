import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import Waves from 'svg/waves.svg';
import WaveTop from 'svg/wave-top.svg';
import WaveBottom from 'svg/wave-bottom.svg';
import Diagram from 'svg/diagram.svg';
import Container from 'components/Container';
import Hero from 'components/Hero';
import NumberedSection from 'components/NumberedSection';
import IndustryApplications from 'components/IndustryApplications';
import EmailListSignup from 'components/EmailListSignup';
import Header from './Header';
import News from './News';
import FeatureShowcase from './FeatureShowcase';
import OurPartners from './OurPartners';
import VideoFeature from './VideoFeature';
import StartDeveloping from './StartDeveloping';
import './HomePage.module.css';

const HomeContentShape = PropTypes.shape({
  features: PropTypes.arrayOf(PropTypes.object),
  partners: PropTypes.arrayOf(PropTypes.object),
  numberedSections: PropTypes.arrayOf(PropTypes.object),
  applications: PropTypes.arrayOf(PropTypes.object),
});

const HomePage = ({
  intl,
  content: {
    features,
    partners,
    numberedSections,
    applications,
  },
}) => (
  <>
    <div styleName="header-container">
      <Container>
        <Header />
      </Container>

      <Waves className="waves" />
    </div>

    <section styleName="mission-statement">
      <WaveTop style={{ transform: 'translateY(-99%)', position: 'absolute' }} />

      <div style={{ color: 'var(--white)' }}>
        <Container>
          <News />
        </Container>
      </div>

      <div>
        <Container>
          <FeatureShowcase features={features} />
        </Container>
      </div>

      <div styleName="diagram">
        <Container>
          <h4>
            {intl.formatMessage({ id: 'home.mission.title' })}
          </h4>

          <Diagram />
        </Container>
      </div>

      <WaveBottom />
    </section>

    <section styleName="number-sections-container">
      <Container>
        <Hero title={intl.formatMessage({ id: 'home.numbered-header.title' })} />
      </Container>

      <Container>
        <div styleName="number-sections">
          {numberedSections.map(({ title, description, ...section }) => (
            <NumberedSection
              {...section}
              title={intl.formatMessage({ id: title })}
              description={description.map((item) => intl.formatMessage({ id: item }))}
              key={title}
            />
          ))}
        </div>
      </Container>
    </section>

    <section>
      <OurPartners partners={partners} />
    </section>

    <section styleName="video-feature">
      <Container>
        <VideoFeature
          title="home.video-feature.title"
          subheading="home.video-feature.subheading"
          videoUrl="https://www.youtube-nocookie.com/embed/hpzhPV3ox_8?autoplay=1"
        />
      </Container>
    </section>

    <section styleName="industry-verticals">
      <IndustryApplications items={applications} />

      <StartDeveloping />
    </section>

    <section>
      <Container>
        <EmailListSignup />
      </Container>
    </section>
  </>
);

HomePage.propTypes = {
  intl: intlShape.isRequired,
  content: HomeContentShape.isRequired,
};

export default injectIntl(HomePage);
