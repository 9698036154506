import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import laptopVideo from 'images/video-thumbnail.png';
import VideoReveal from '../VideoReveal';
import './VideoFeature.module.css';

const VideoFeature = ({
  intl,
  title,
  subheading,
  videoUrl,
}) => (
  <div styleName="video-feature">
    {title && (
      <h3 styleName="title">
        {intl.formatMessage({ id: title })}
      </h3>
    )}

    {subheading && (
      <p styleName="subheading">
        {intl.formatMessage({ id: subheading })}
      </p>
    )}

    <VideoReveal videoUrl={videoUrl} previewPhoto={laptopVideo} />
  </div>
);

VideoFeature.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  videoUrl: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

VideoFeature.defaultProps = {
  title: '',
  subheading: '',
};

export default injectIntl(VideoFeature);
