import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import Container from 'components/Container';
import './OurPartners.module.css';

const OurPartners = ({ intl, partners }) => {
  if (partners.length === 0) {
    return null;
  }

  return (
    <div styleName="our-partners">
      <Container>
        <h3 styleName="title">
          {intl.formatMessage({ id: 'common.our-partners' })}
        </h3>

        <div styleName="partner-list">
          {partners.map(({ name, src }) => (
            <img src={src} alt={name} key={name} />
          ))}
        </div>
      </Container>
    </div>
  );
};

const partnerShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  src: PropTypes.string,
});

OurPartners.propTypes = {
  intl: intlShape.isRequired,
  partners: PropTypes.arrayOf(partnerShape),
};

OurPartners.defaultProps = {
  partners: [],
};

export default injectIntl(OurPartners);
