import React from 'react';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import laptopMockup from 'images/wireframe-3.png';
import './Header.module.css';

const Header = ({ intl }) => (
  <div styleName="header">
    <div styleName="header-text">
      <div styleName="title">
        <h4>
          {intl.formatMessage({ id: 'home.header.title' })}
        </h4>
      </div>

      <p>
        {intl.formatMessage({ id: 'home.header.description' })}
      </p>
    </div>

    <div styleName="illustration">
      <img
        src={laptopMockup}
        alt={intl.formatMessage({ id: 'home.header.laptop' })}
      />
    </div>

    <div className="dots" />
  </div>
);

Header.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Header);
