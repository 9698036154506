/* eslint-disable react/prop-types */
import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import { useCompanyName } from 'hooks/companyName';
import Layout from 'components/Layout';
import HomePage from 'components/pages/HomePage';

const IndexPage = ({ data }) => (
  <Layout>
    <Helmet title={useCompanyName()} />

    <HomePage content={data.allHomeJson.nodes[0]} />
  </Layout>
);

export const query = graphql`
  {
    allHomeJson {
      nodes {
        features {
          id
          src
        }
        partners {
          name
          src
        }
        applications {
          src
          solutions
          title
        }
        numberedSections {
          description
          img {
            ...HeroImage
          }
          node
          title
        }
      }
    }
  }
`;

export default IndexPage;
