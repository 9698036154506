import React from 'react';
import PropTypes from 'prop-types';
import { Link, injectIntl, intlShape } from 'gatsby-plugin-intl';

import ArrowRight from 'svg/arrow-right-solid.svg';
import './NewsItem.module.css';

function ItemUrl({ intl, url, blogPost }) {
  if (!url && !blogPost) {
    return null;
  }

  if (blogPost) {
    return (
      <Link to={`/blog${blogPost.uri}`} styleName="news-item-link">
        {intl.formatMessage({ id: 'common.read-more' })}

        <ArrowRight />
      </Link>
    );
  }

  return (
    <a href={url} styleName="news-item-link">
      {intl.formatMessage({ id: 'common.read-more' })}

      <ArrowRight />
    </a>
  );
}

ItemUrl.propTypes = {
  intl: intlShape.isRequired,
  url: PropTypes.string,
  blogPost: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

ItemUrl.defaultProps = {
  url: null,
  blogPost: null,
};

function NewsItem({
  intl,
  item: {
    category,
    title,
    url,
    imgSrc,
    blogPost,
  },
}) {
  return (
    <div styleName="news-item">
      <img src={imgSrc} alt="" />

      <h6>
        {category}
      </h6>

      <p>
        {title}
      </p>

      <ItemUrl intl={intl} url={url} blogPost={blogPost} />
    </div>
  );
}

const NewsItemShape = PropTypes.shape({
  category: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  blogPost: PropTypes.shape({ uri: PropTypes.string }),
  imgSrc: PropTypes.string,
});

NewsItem.propTypes = {
  intl: intlShape.isRequired,
  item: NewsItemShape.isRequired,
};

export default injectIntl(NewsItem);
