import React from 'react';
import { Link, injectIntl, intlShape } from 'gatsby-plugin-intl';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/Container';
import Button from 'components/Button';
import NavLink from 'components/NavLink';
import './StartDeveloping.module.css';

function SupportDocs({ intl }) {
  const { site: { siteMetadata: { forumURL, docsURL } } } = useStaticQuery(graphql`
    query SupportDocsMeta {
      site {
        siteMetadata {
          forumURL
          docsURL
        }
      }
    }
  `);

  return (
    <div styleName="support-docs-container">
      <Container>
        <div styleName="support-docs">
          <h4>
            {intl.formatMessage({ id: 'home.developers.support-title' })}
          </h4>

          <div>
            <div>
              <p>
                {intl.formatMessage({ id: 'home.developers.forum' })}
              </p>

              <NavLink to={forumURL} external>
                {intl.formatMessage({ id: 'home.developers.join-forum' })}
              </NavLink>
            </div>

            <span styleName="divider" />

            <div>
              <p>
                {intl.formatMessage({ id: 'home.developers.docs' })}
              </p>

              <NavLink to={docsURL} external>
                {intl.formatMessage({ id: 'home.developers.view-docs' })}
              </NavLink>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

SupportDocs.propTypes = {
  intl: intlShape.isRequired,
};

const StartDeveloping = ({ intl }) => (
  <>
    <div styleName="container">
      <Container>
        <div styleName="start-developing">
          <h3>
            {intl.formatMessage({ id: 'home.developers.title' })}
          </h3>

          <p>
            {intl.formatMessage({ id: 'home.developers.subheading' })}
          </p>
        </div>

        <video styleName="terminal" muted autoPlay playsInline loop>
          <source src="/terminal-demo.mp4" type="video/mp4" />
        </video>
      </Container>
    </div>

    <SupportDocs intl={intl} />

    <div styleName="cta-button">
      <Container>
        <Link to="/download">
          <Button type="button">
            {intl.formatMessage({ id: 'common.deploy-today' })}
          </Button>
        </Link>
      </Container>
    </div>
  </>
);

StartDeveloping.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(StartDeveloping);
