import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import './Card.module.css';

const Card = ({ intl, card: { title, solutions, src }, ...props }) => (
  <div styleName="card" key={title} {...props}>
    <header>
      <img src={src} alt="" />

      <h5>
        {intl.formatMessage({ id: title })}
      </h5>
    </header>

    <div>
      <ul>
        {solutions.map((solution) => (
          <li key={solution}>
            {intl.formatMessage({ id: solution })}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const ApplicationType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  solutions: PropTypes.arrayOf(PropTypes.string).isRequired,
  src: PropTypes.string.isRequired,
});

Card.propTypes = {
  intl: intlShape.isRequired,
  card: ApplicationType.isRequired,
};

export default injectIntl(Card);
