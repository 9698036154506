import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import CTAButton from './CTAButton';
import './NumberedSection.module.css';

const NODES = {
  'cta-button': <CTAButton />,
};

const NumberedSection = ({
  img,
  title,
  description,
  node,
  aside,
}) => (
  <div styleName="number-section" data-aside={!!aside}>
    <aside>
      {aside && (
        aside
      )}

      {!aside && (
        <Img fluid={img.childImageSharp.fluid} />
      )}
    </aside>

    <div>
      <h5 styleName="heading">
        {title}
      </h5>

      {description.map((text) => (
        <p key={text}>
          {text}
        </p>
      ))}

      {node && NODES[node] && (
        <div styleName="node">
          {NODES[node]}
        </div>
      )}
    </div>
  </div>
);

NumberedSection.propTypes = {
  img: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  title: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  node: PropTypes.node,
  aside: PropTypes.node,
};

NumberedSection.defaultProps = {
  img: undefined,
  node: undefined,
  aside: undefined,
};

export default NumberedSection;
