import React from 'react';
import PropTypes from 'prop-types';
import { Link, injectIntl, intlShape } from 'gatsby-plugin-intl';
import Loadable from '@loadable/component';

import Container from 'components/Container';
import Button from 'components/Button';
import Card from './Card';
import './IndustryApplications.module.css';

// Using Loadable because this component uses 'window' in a way that doesn't work with SSR
// See: https://www.gatsbyjs.org/docs/using-client-side-only-packages/#workaround-3-load-client-side-dependent-components-with-loadable-components
const ReactSimpleCarousel = Loadable(() => import('react-spring-carousel'));

const ApplicationType = PropTypes.shape({
  title: PropTypes.string.isRequired,
});

const IndustryApplications = ({ intl, items }) => {
  const itemsVisible = 3;

  if (items.length === 0) {
    return null;
  }

  const cards = items.map((item) => (
    <Card card={item} key={item.title} />
  ));

  return (
    <div styleName="container">
      <Container>
        <h3 styleName="heading">
          {intl.formatMessage({ id: 'home.applications-title' })}
        </h3>

        <p>
          {intl.formatMessage({ id: 'home.applications-description' })}
        </p>
      </Container>

      <div styleName="content">
        <ReactSimpleCarousel slidesToShow={itemsVisible}>
          {cards}
        </ReactSimpleCarousel>
      </div>

      <Container>
        <div styleName="buttons">
          <Link to="/contact/">
            <Button type="button" style={{ padding: '0.75em 5.5em' }}>
              {intl.formatMessage({ id: 'common.talk-to-export' })}
            </Button>
          </Link>

          <Link to="/pricing/">
            <Button type="button" style={{ padding: '0.75em 3.5em' }}>
              {intl.formatMessage({ id: 'common.see-pricing' })}
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

IndustryApplications.propTypes = {
  items: PropTypes.arrayOf(ApplicationType),
  intl: intlShape.isRequired,
};

IndustryApplications.defaultProps = {
  items: [],
};

export default injectIntl(IndustryApplications);
