import React from 'react';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import Button from 'components/Button';
import NavLink from 'components/NavLink';

const CTAButton = ({ intl }) => (
  <NavLink
    to="/download/"
    aria-label={intl.formatMessage({ id: 'common.deploy-today' })}
  >
    <Button style={{ padding: '0.75em 3.5em' }}>
      {intl.formatMessage({ id: 'common.deploy-today' })}
    </Button>
  </NavLink>
);

CTAButton.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CTAButton);
