import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import PlayButton from 'svg/play-button.svg';
import './VideoReveal.module.css';

const VideoReveal = ({ intl, videoUrl, previewPhoto }) => {
  const [revealed, setRevealed] = useState(false);

  if (!revealed) {
    return (
      <button
        type="button"
        styleName="reveal-button"
        onClick={() => {
          setRevealed(true);
        }}
      >
        <PlayButton />

        <img
          src={previewPhoto}
          alt="Laptop with video preview"
        />
      </button>
    );
  }

  return (
    <iframe
      title="Brainframe video"
      width="100%"
      height="500"
      src={videoUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

VideoReveal.propTypes = {
  intl: intlShape.isRequired,
  videoUrl: PropTypes.string.isRequired,
  previewPhoto: PropTypes.string.isRequired,
};

export default injectIntl(VideoReveal);
