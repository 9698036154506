import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';
import Loadable from '@loadable/component';

import illustrationOne from 'images/news-01.png';
import illustrationTwo from 'images/news-02.png';
import illustrationThree from 'images/news-03.png';
import NewsItem from './NewsItem';
import './News.module.css';

// Using Loadable because this component uses 'window' in a way that doesn't work with SSR
// See: https://www.gatsbyjs.org/docs/using-client-side-only-packages/#workaround-3-load-client-side-dependent-components-with-loadable-components
const ReactSimpleCarousel = Loadable(() => import('react-spring-carousel'));

function News({ intl }) {
  // Here we get the 3 most recent "newscard" category posts from wordpress
  const data = useStaticQuery(graphql`
    query News {
      posts: allWpPost(
        limit: 3
        sort: { fields: date, order: DESC }
        filter: {
          categories: {
            nodes: { elemMatch: { slug: { eq: "newscard" } } }
          }
        }
      ) {
        nodes {
          databaseId
          newsCard {
            title
            url
            category
            blogPost {
              ... on WpPost {
                uri
              }
            }
          }
        }
      }
    }
  `);
  const { nodes: posts } = data.posts;

  // We only ever render three items. These are used as default images for them
  const DEFAULT_IMAGES = [
    illustrationOne,
    illustrationTwo,
    illustrationThree,
  ];

  // If there are no news items for some reason, just don't render anything
  if (posts.length === 0) {
    return null;
  }

  return (
    <div styleName="news">
      <h4 styleName="title">
        {intl.formatMessage({ id: 'common.news-title' })}
      </h4>

      <div styleName="news-items">
        {posts.slice(0, 3).map((item, index) => (
          <div key={item.databaseId} style={{ display: 'flex', justifyContent: 'center' }}>
            <NewsItem
              intl={intl}
              item={{ ...item.newsCard, imgSrc: DEFAULT_IMAGES[index] }}
            />
          </div>
        ))}
      </div>

      <div styleName="news-carousel">
        <ReactSimpleCarousel slidesToShow={3}>
          {posts.slice(0, 3).map((item, index) => (
            <NewsItem
              intl={intl}
              item={{ ...item.newsCard, imgSrc: DEFAULT_IMAGES[index] }}
              key={item.databaseId}
            />
          ))}
        </ReactSimpleCarousel>
      </div>
    </div>
  );
}

News.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(News);
