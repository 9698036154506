import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, injectIntl, intlShape } from 'gatsby-plugin-intl';

import Button from 'components/Button';
import './FeatureShowcase.module.css';

/**
 * Something to be aware of when running the website in development, it seems like setting
 * the 'currenttime' attribute causes some browsers to loop the videos incorrectly (restarting
 * before they have fully ended). This is because some browser's expect a 206 'partial response'
 * with a range header, and the development server doesn't do this.
 * It works fine once deployed to cloudfront.
 * Background: https://stackoverflow.com/a/9549404/1276523
 */
const FeatureShowcase = ({ intl, features }) => {
  const currentVideo = useRef(null);
  const [featureIndex, setFeature] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!currentVideo) {
      return;
    }

    // When the current video changes, we want to play from the beginning
    currentVideo.current.currentTime = 0;
    currentVideo.current.play().catch((error) => {
      // do nothin
    });
  }, [featureIndex]);

  if (features.length === 0) {
    return null;
  }

  function handleClick(index) {
    return () => {
      setFeature(index);
    };
  }

  return (
    <div styleName="feature-showcase">
      <div>
        <h4 styleName="title">
          {intl.formatMessage({ id: 'home.feature-showcase.title' })}
        </h4>

        <nav styleName="nav">
          {features.map((feature, index) => (
            <button
              type="button"
              key={feature.id}
              data-active={index === featureIndex}
              onClick={handleClick(index)}
            >
              {intl.formatMessage({ id: feature.id })}
            </button>
          ))}
        </nav>

        <Link to="/download" aria-label={intl.formatMessage({ id: 'common.try_brainframe' })}>
          <div styleName="cta-button">
            <Button type="button">
              Get BrainFrame
            </Button>
          </div>
        </Link>
      </div>

      <div styleName="media">
        {features.map((feature, index) => (
          <div key={feature.id}>
            <video
              ref={index === featureIndex ? currentVideo : undefined}
              currenttime={index === featureIndex ? 0 : undefined}
              autoPlay={index === featureIndex}
              playsInline
              preload="auto"
              alt={intl.formatMessage({ id: feature.id })}
              data-active={index === featureIndex}
              data-loaded={loaded}
              loop
              muted
              onLoadedData={() => setLoaded(true)}
            >
              <source src={feature.src} type="video/mp4" />
            </video>
          </div>
        ))}
      </div>
    </div>
  );
};

const Feature = PropTypes.shape({
  id: PropTypes.string.isRequried,
  src: PropTypes.string.isRequired,
});

FeatureShowcase.propTypes = {
  intl: intlShape.isRequired,
  features: PropTypes.arrayOf(Feature),
};

FeatureShowcase.defaultProps = {
  features: [],
};

export default injectIntl(FeatureShowcase);
