import React from 'react';
import PropTypes from 'prop-types';

import './Hero.module.css';

const Hero = ({ title, text }) => (
  <div styleName="hero">
    <h3>
      {title}
    </h3>

    {text && (
      <p>
        {text}
      </p>
    )}
  </div>
);

Hero.propTypes = {
  title: PropTypes.node.isRequired,
  text: PropTypes.node,
};

Hero.defaultProps = {
  text: '',
};

export default Hero;
